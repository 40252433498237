<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-select v-model="params.rankingDateType" placeholder="排行日期类型">
          <el-option label="昨日" :value="1"></el-option>
          <el-option label="本周" :value="2"></el-option>
          <el-option label="本月" :value="3"></el-option>
          <el-option label="总排行" :value="4"></el-option>
        </el-select>
        <!-- 字段选择 -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
      <el-table
        header-row-class-name="table-header"
        ref="putCrowdRankTable"
        class="put-crowd-table"
        :data="dataList"
        style="width: 100%"
        height="690px"
        v-loading="loading"
        empty-text="暂无数据"
        @sort-change="handleSortChange"
      >
        <el-table-column type="index" align="center" width="50">
          <template slot-scope="scope">
            <img
              v-if="scope.$index === 0"
              src="@/assets/first.png"
              alt="first"
              class="rank-icon"
            />
            <img
              v-else-if="scope.$index === 1"
              src="@/assets/second.png"
              alt="second"
              class="rank-icon"
            />
            <img
              v-else-if="scope.$index === 2"
              src="@/assets/third.png"
              alt="third"
              class="rank-icon"
            />
            <span v-else class="rank-text">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in showField"
          :key="index"
          :prop="item.fieldCode"
          :label="item.fieldName"
          :sortable="sortableType(item)"
          :align="filAlign(item)"
          :min-width="item.width + 'px' || '100px'"
        >
          <template slot-scope="scope">
            <div v-if="item.hasChild">
              <div v-for="(item2, index2) in item.children" :key="index2">
                {{ item2.fieldName }}
                <span :style="'color:' + item.fontColor">
                  {{ scope.row[item2.fieldCode] }}
                </span>
              </div>
            </div>
            <span v-else :style="'color:' + item.fontColor">
              {{ scope.row[item.fieldCode] | sortField(item) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { formatThousandsDots } from "@/assets/js/utils";
import SelectField from "@/components/SelectField";
import { getProfitRankingByDate, getFields } from "@/api/statics";
import { yesterdayOptions, labelList } from "@/assets/js/options";
import { mapActions } from "vuex";
let timer = null;
export default {
  name: "incomeRank",
  components: {
    SelectField,
  },
  computed: {
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
    };
  },
  methods: {
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      const tmpform = {
        rankingDateType: this.params.rankingDateType,
        showFieldCodes: tmpFields,
      };
      getProfitRankingByDate(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res;
          // 缓存参数
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpform,
              cacheShowField: this.showField,
              cacheSendField: this.sendField,
            },
          });
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds() {
      getFields({ dataType: "profitRankingByDate" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData("created");
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    this.$set(this.params, "rankingDateType", 1);
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
    clearInterval(timer);
    timer = setInterval(this.getData, 1000 * 5 * 60);
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
</style>
